.why-us-container {
  margin-top: 15px;
}

.about-banner-row {
  background-image: url("../../Assets//SKImages//aboutBanner.png");
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
}

.why-us-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.why-us-heading h2 {
  color: #1b3a57;
  font-size: 48px;
  font-weight: 700;
}

.why-us-heading p {
  color: #000;
  font-size: 18px;
  padding: 10px 80px;
}

.experience-clients-div {
  margin-top: 50px;
  padding: 40px;
  border: 1px solid #857f7f;
  border-radius: 20px;
  display: flex;
}

.experience-clients-column {
  border-right: 1px solid #857f7f;
  padding: 0 20px;
}

.experience-clients-column-heading h2 {
  color: #1b3a57;
  font-size: 30px;
}

.experience-clients-column-heading span {
  color: #000;
  font-size: 18px;
  margin-top: -8px;
  font-weight: 700;
}

.experience-clients-column-heading img {
  width: 56px;
  height: 56px;
  margin-top: 10px;
}

.mission-img {
  width: 500px;
  height: 350px;
}

.mission-container {
  margin-top: 50px;
}

.mission-container h2 {
  text-align: start;
  color: #1b3a57;
  padding-top: 4px;
  font-size: 48px;
}

.mission-container p {
  text-align: start;
}

.our-values-container {
  margin-top: 50px;
  background-image: url("../../Assets/icons/curve-line.png");
  background-repeat: no-repeat;
  background-position: right -150px top 100px;
  background-size: contain;
}

.our-value-cards img {
  width: 60px;
  height: 60px;
}

.our-value-cards {
  display: flex;
  justify-content: start;
  flex-direction: column;
  width: 250px;
  padding: 10px 24px;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.our-value-cards h3 {
  color: #1b3a57;
  font-size: 22px;
  text-align: left;
  padding-top: 15px;
}

.our-value-cards p {
  font-size: 16px;
  text-align: left;
  padding-top: 15px;
}

.our-value-cards a {
  color: #1b3a57;
  font-size: 18px;
  text-align: left;
  font-weight: 600;
  padding-top: 15px;
}

.our-value-cards:hover {
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.our-values-right h2 {
  text-align: start;
  color: #1b3a57;
  padding-top: 4px;
  font-size: 48px;
}

.our-values-right p {
  text-align: start;
  padding-top: 20px;
}

.our-values-right {
  margin-top: 140px;
}

.how-it-works-container {
  margin-top: 50px;
}

.how-it-works-column img {
  width: 80px;
  height: 80px;
}

.how-it-works-column h2 {
  color: #1b3a57;
  font-size: 22px;
  text-align: center;
  padding-top: 10px;
}

.how-it-works-column p {
  font-size: 16px;
  text-align: center;
  padding-top: 5px;
}

.how-it-works-column {
  width: 200px;
  height: 300px;
  justify-content: center;
  align-content: center;
}

.how-it-works-row {
  display: flex;
  margin-top: 30px;
  justify-content: center;
}

.joint-curve-img {
  width: 250px;
  height: 80px;
}

.about-get-in-touch-container {
  background-color: #d3d3d3;
  padding: 70px 150px;
  margin-top: 50px;
}

.about-get-in-touch-social-icons h3 {
  font-size: 22px;
  color: #1b3a57;
  text-align: left;
}

.about-get-in-touch-social-icons h5 {
  font-size: 18px;
  color: #59595f;
  padding-top: 3px;
  text-align: left;
}

.about-get-in-touch-social-icons h6 {
  font-size: 16px;
  color: #59595f;
  padding-top: 10px;
  text-align: left;
}

.about-get-in-touch-social-icons {
  width: 300px;
}

.get-in-touch-send-button {
  background-color: #1b3a57;
  padding: 10px 20px;
  color: #ffffff;
  width: fit-content;
  border: none;
  border-radius: 20px;
}

.about-get-in-touch-row {
  display: flex;
  gap: 50px;
}
.how-it-works-paragraph,
.why-us-paragraph {
  text-align: center !important;
}

@media (max-width: 1024px) {
  .how-it-works-row {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center each column horizontally */
    gap: 20px; /* Optional: adds space between columns for better readability */
  }

  .how-it-works-column {
    max-width: 300px; /* Set a max width to keep columns from stretching too wide */
    text-align: center; /* Center-align text within each column */
  }

  .joint-curve-img {
    rotate: 90deg;
    width: 180px;
    height: 80px;
    margin: 50px;
  }
}

@media (max-width: 768px) {
  .why-us-heading p {
    padding: 10px 30px;
    text-align: justify;
  }

  .experience-clients-div {
    flex-direction: column;
    width: 90%;
    margin: auto;
  }

  .experience-clients-column {
    border: none;
  }
  .experience-clients-column-heading {
    justify-content: left !important;
  }

  .our-values-container {
    background-image: none;
  }

  .mission-img {
    width: 350px !important;
    height: 300px;
    margin-top: 5px;
  }

  .our-value-cards {
    width: 425px;
    margin: auto;
  }

  .our-values-right {
    margin-top: 70px;
  }

  .how-it-works-row {
    flex-direction: column;
    margin-left: -30px !important;
  }

  .how-it-works-column {
    margin: auto;
  }

  .joint-curve-img {
    rotate: 90deg;
    width: 180px;
    height: 80px;
    margin: 50px;
  }

  .about-get-in-touch-row {
    flex-direction: column;
  }

  .about-get-in-touch-container {
    padding: 40px 50px;
  }

  .mission-container p {
    text-align: justify;
    padding-left: 10px !important;
    padding-right: 11px !important;
  }

  .our-values-right p {
    text-align: justify;
    padding-left: 10px !important;
    padding-right: 11px !important;
  }

  .media-banner-content p {
    font-size: 15px !important;
  }

  .media-banner-content {
    margin-left: 1rem !important;
    padding-left: 20px !important;
    /* padding-right: 20px !important; */
  }

  .why-us-icon {
    width: 32px !important;
    height: 32px !important;
    margin-top: 1px !important;
  }
  .why-us-happyclient {
    margin-left: -42px;
  }
  .why-us-count {
    margin-left: 20px;
  }
  .banner-content .about-banner-h2 {
    font-size: 24px !important;
  }
  .media-banner-content ms-5 {
    padding-left: 20px !important;
  }
  .how-it-works-paragraph {
    text-align: left !important;
  }
  .our-values-right h2 {
    font-size: 40px !important;
    padding-left: 8px !important;
  }
  .mission-container h2 {
    padding-left: 8px !important;
    font-size: 40px !important;
  }
  .why-us-heading h2 {
    font-size: 37px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .mission-img {
    width: 696px !important;
  }
  .our-value-cards {
    width: 668px;
  }
}
@media (max-width: 1024px) {
  .mission-container .col-lg-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mission-img {
    width: 100%;
    height: auto;
  }
}
