
/* /faq.css */
.Frequently-asked-questions{
    margin-top: 50px;
    margin-bottom: 50px;
}

.faq-section {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    
  }
  
  .faq-heading {
    font-size: 2.5em;
    margin-bottom: 10px;
    text-align: center;
    color: #243752;
  }
  
  .faq-intro {
    font-size: 1.2em;
    margin-bottom: 20px;
    text-align: center;
    color: #555;
  }
  
  .faq-item {
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .faq-question-box {
    background-color: #243752;
    color: #fff;
    padding: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .qn-container{
    margin-top: 50px;
  }
  .faq-question {
    margin: 0;
    font-size: 1.2em;
  }
  
  .faq-toggle-icon {
    font-size: 1.5em;
  }
  
  .faq-answer-box {
    padding: 15px;
    background-color: #FFFFFF;
    border: 1px solid #ddd;
    border-radius: 10px;
    border-color:#243752;
  }
  
  .faq-answer {
    margin: 0;
    font-size: 1em;
    color: #333;
    border-color: #243752;
    text-align: left;
  }
  @media (max-width: 768px)  {
    .faq-section{
      width: 100%;
    }
    .faq-intro{
      text-align: left;
      font-size: 1em;
    }
    .faq-heading{
      margin-bottom:20px !important;
      font-size: 22px;
    }
    .faq-question{
      font-size: 1em;
      text-align: left;
    }
  }
 
  /*getintouch.css*/

  .get-in-container {
  /* background-color: #d3d3d3; */
  background-image: url("../../Assets//SKImages/cta1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
 padding-left: 10px;
  /* width: 90%; */
  display:flex;
}
.faq-get-in-touch-button {
  position: relative; 
  overflow: hidden; 
  font-size: 16px;
  color: #FFFFFF;
  padding: 13px 8px;
  margin-top: 2px;
  /* margin-bottom: 20px; */
  border-radius: 1rem;
  background-color: #1B3A57; 
  width: fit-content;
  /* border: 1px solid #1B3A57;  */
  cursor: pointer;
  z-index: 1;
}

.faq-get-in-touch-button:hover {
  color: #1B3A57; 
}

.faq-get-in-touch-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: linear-gradient(82.3deg, #d3d3d3, #D3d3d3); 
  transform: scaleX(0); 
  transform-origin: 0 50%;
  transition: transform 0.475s ease; 
  z-index: -1; 
}
.faq-get-in-touch-button:hover::before {
  transform: scaleX(1); 

}
.get-in-img {
  width: 100%;
  height: 270px;
  margin-right: -26px;
}
.title-get h2{
  text-align: left;
}
.title-get p{
  padding-top: 5px;
  text-align: left;
}
.title-get{
  align-content: center;
  padding: 60px;
}
@media (max-width: 768px) {
  .get-in-container{
    flex-direction: column;
  }
  .title-get p{
    width: 200px;
  }
  .title-get{
    padding:20px 0 20px 0
  }
}
