.sign-page {
    background-image: url(../../Assets/SKImages/form-bg.png);
    height: 650px;
    width: 80%; 
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px;
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 30px auto; 
}


.form-container {
    display: flex;
    height: 100%;
    width: 90%;
    border-radius: 20px;
    overflow: hidden;
    /* background-color: #FAFAFA; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    /* margin-left: -130px; */
}

.left-section {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    background-color: #f7f7f7;
    padding: 20px;
    /* text-align: center; */
    /* margin-left: -50px; */
}

.left-section h1 {
    margin-bottom: 20px;
    color: #333;
    font-weight: bold;
    text-align: left;
    padding-left: 50px;
}

.image-container {
    width: 100%;
    height: auto;
    justify-content: start;
}

.right-section {
    flex: 1;
    background-color: white;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2px solid #1b3a57; 
    height: auto; 
    border-radius: 20px;
    width: 70%; 
    margin: auto; 
    margin-left: -50px;
}

.right-section h2 {
    margin-bottom: 20px;
    color: #2c3e50;
    font-size: 20px;
    font-weight: bold;
}

.login-form {
    display: flex;
    flex-direction: column;
}

.login-form label {
    margin-bottom: 5px;
    color: #2c3e50;
    text-align: left;
    font-weight: 600;
}

.login-form input, .login-form .password-container input {
    margin-bottom: 5px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 15px;
}

.form-options {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 20px;
}

.form-options label {
    /* display: flex; */
    align-items: center;
    color: #2c3e50;
}

.form-options input[type="checkbox"] {
    margin-right: 0px;
}

.forgot-password {
    color: #3498db;
    text-decoration: none;
    border: none;
    background-color: #fff;
}

.forgot-password:hover {
    text-decoration: underline;
}

.login-btn {
    background-color: #1b3a57;
    color: #f7f7f7;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    width: 40%;
}

.register-btn {
    background-color: #fff;
    color: #1b3a57;
    border: 1px solid #1b3a57;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 40%;
    font-weight: 600;
}

.login-btn:hover, .register-btn:hover {
    opacity: 0.9;
}
.password-container {
    position: relative;
    display: flex;
    align-items: center;
}
.password-container-signin {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
.signin-password-placeholder{
    padding-top: 10px;
    padding-left: 0px;
}


.password-container input {
    flex: 1;
    padding-right: 0px; 
    box-sizing: border-box; 
}

.password-toggle {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
}

.password-toggle .anticon {
    font-size: 20px;
}
.invalid{
    border: 1px solid red !important;
}
.error {
    color: red;
    font-size: 0.875em;
    margin-top: 0.25em;
}

:where(.css-dev-only-do-not-override-17seli4).ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    color: #1b3a56;
    background: #1b3a57
}

.faq-get-in-touch-button-signout {
    position: relative; 
    overflow: hidden; 
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    padding: 0px 20px;
    margin-top: 2px;
    margin-bottom: 20px;
    border-radius: 1.5rem;
    background-color: #1B3A57; 
    width: fit-content;
    border: 1px solid #1B3A57; 
    cursor: pointer;
    z-index: 1;
  }
  
  .faq-get-in-touch-button-signout:hover {
    color: #1B3A57; 
  }
  
  .faq-get-in-touch-button-signout::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: linear-gradient(82.3deg, #d3d3d3, #D3d3d3); 
    transform: scaleX(0); 
    transform-origin: 0 50%;
    transition: transform 0.475s ease; 
    z-index: -1; 
  }
  .faq-get-in-touch-button-signout:hover::before {
    transform: scaleX(1); 
  
  }
.signin-remenber-label{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.signin-remenber-label input{
    margin-bottom: 0px !important;
}
.signin-password-field {
    padding: 0px 0px 6px 0px;
    margin-bottom: 0px !important;
}
:where(.css-dev-only-do-not-override-17seli4).ant-input-affix-wrapper .ant-input-suffix {
    margin-inline-start: 4px;
    margin-right: 15px;
}
@media (max-width: 768px) {
    .form-container {
        height: 100% !important;
        width: 95% !important;
        border-radius: 20px !important;
        overflow: hidden !important;
    }
    .sign-page{
        background-image: none;
        width: 100%;
        height: auto;
        padding-left: 18px !important;
    }
    .left-section {
        display: none;
    }
    .right-section {
        background-color: white;
        padding: 35px 15px 35px 15px;
        flex-direction: column;
        /* justify-content: center; */
        border: 2px solid #1b3a57; 
        height: auto; 
        border-radius: 20px;
        width: 100%; 
        margin: auto;
        
    }
    .signin-success-message{
        margin: 40px;
        width: 310px;
    }
   
}
.forgot-password-div h2{
    color:#1b3a57;
    /* padding-top: 20px; */
}
.forgot-password-div{
    padding: 10px;
}
.continue-button{
    margin-top:20px;
    background-color: #1b3a57;
    color:#FFFFFF
}
.forgot-password-div p{
    font-size: 13px !important;
}
/* .token-expiry-login-button{
    background-color: #1b3a57;
    padding: 4px;
    border-radius: 20px;
    color: #fff;
} */
 .forgotPassword-p-tag{
    padding-top: 20px;
 }
 .code-inputs .input-otp {
    display: flex !important;
    width: 100% !important;
    /* gap:10px !important; */
    justify-content: space-around !important;
}
.input-otp {
    width: 100% !important;
    display: flex !important;
    /* gap: 10px !important; */
    justify-content: space-around !important;
}