/* ====home banner css start======== */

.home-banner-1 {
  background-image: url("../../Assets/SKImages/newSlider1.png");
  height: 450px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex !important;
}
.home-banner-2 {
  background-image: url("../../Assets/SKImages/newSlider2.png");
  height: 450px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex !important;
}
.home-banner-3 {
  background-image: url("../../Assets/SKImages/newSlider3.png");
  height: 450px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex !important;
}
.banner-content {
  padding-top: 100px;
  text-align: left;
}
.banner-content h2 {
  color: #1b3a57;
  font-weight: 400;
  font-size: 35px;
  letter-spacing: 1px;
}
.banner-content h1 {
  color: #1b3a57;
  font-weight: 700;
  font-size: 48px;
  letter-spacing: 1px;
}
.banner-content span {
  color: #6a6a6c;
}
.banner-content p {
  color: #1b3a57;
  font-size: 20px;
  letter-spacing: 0.8px;
}
.banner-content-div {
  background-color: #6a6a6c;
  color: #fff;
  border-radius: 10px;
  padding: 5px 15px;
  width: fit-content;
  display: flex;
  align-content: center;
  text-align: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}
.banner-content-div p {
  color: #fff;
  margin-bottom: 0;
}

/* .explore-button{
    background-color: #1B3A57;
    padding: 8px;
    color:#FFFFFF;
    width:fit-content;
    border: none;
    border-radius: 5px;
} */
.home-banner-buttons {
  background-color: #1b3a57;
  padding: 8px;
  color: #ffffff;
  width: fit-content;
  border: none;
  border-radius: 5px;
  align-items: center;
}
.home-shop-now {
  position: absolute;
  bottom: 70px;
  display: flex;
  justify-content: end;
}
.button-adjust-1 {
  margin-top: 350px;
  margin-right: -200px;
}
.button-adjust-2 {
  margin-top: 380px;
  margin-right: -500px;
}
.button-adjust-3 {
  margin-top: 350px;
  margin-right: -300px;
}

.cement-img {
  width: 280px;
  height: 250px;
}
.home-banner-slide .slick-slide {
  padding: 0px;
}
.container-fluid {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.home-shop-now-button{
  
}
@media (min-width: 768px) and (max-width: 1024px) {
  .banner-content,
  .home-banner-1 {
    padding-left: 10px !important;
  }
  .banner-content h2 {
    font-size: 18px !important;
  }
  .banner-content h1 {
    font-size: 25px !important;
  }
  .banner-content p {
    display: none;
  }
  .banner-content h4 {
    font-size: 20px !important;
    padding-top: 30px;
  }
  .banner-content-div {
    display: none;
  }
  .home-banner-1 {
    background-image: url("../../Assets/SKImages/1.png");
    height: 450px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex !important;
  }
  .home-banner-2 {
    background-image: url("../../Assets/SKImages/2\ \(1\).png");
    height: 450px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex !important;
  }
  .home-banner-3 {
    background-image: url("../../Assets/SKImages/3.png");
    height: 450px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex !important;
  }
  .banner-content {
    padding-top: 0px !important;
  }
  .mobile-color-change {
    color: #1b3a57 !important;
  }
  .mobile-div-hide {
    display: none !important;
  }
  .arrow-icon-left {
    margin-left: 0 !important;
  }
  .arrow-icon-right {
    margin-right: 0 !important;
  }
  .arrow-icons-left {
    margin-left: -10px !important ;
  }
  .arrow-icons-right {
    margin-right: -20px !important;
  }
  .showing-button {
    justify-content: flex-start !important;
  }
}
@media (max-width: 768px) {
  .banner-content,
  .home-banner-1 {
    padding-left: 10px !important;
  }
  .banner-content h2 {
    font-size: 18px !important;
  }
  .banner-content h1 {
    font-size: 25px !important;
  }
  .banner-content p {
    display: none;
  }
  .banner-content h4 {
    font-size: 20px !important;
    padding-top: 30px;
  }
  .banner-content-div {
    display: none;
  }
  .home-banner-1 {
    background-image: url("../../Assets/SKImages/1.png");
    height: 450px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex !important;
  }
  .home-banner-2 {
    background-image: url("../../Assets/SKImages/2\ \(1\).png");
    height: 450px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex !important;
  }
  .home-banner-3 {
    background-image: url("../../Assets/SKImages/3.png");
    height: 450px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex !important;
  }
  .banner-content {
    padding-top: 0px !important;
  }
  .mobile-color-change {
    color: #1b3a57 !important;
  }
  .mobile-div-hide {
    display: none !important;
  }
  .arrow-icon-left {
    margin-left: 0 !important;
  }
  .arrow-icon-right {
    margin-right: 0 !important;
  }
  .arrow-icons-left {
    margin-left: -30px !important ;
  }
  .arrow-icons-right {
    margin-right: -40px !important;
  }
  .showing-button {
    justify-content: flex-start !important;
  }
}
@media (max-width: 400px) {
  h4.home-banner-h4-purchase-cement {
    font-size: 15px !important;
  }
}
/* ====home banner css End======== */
/* ====our Product css start======== */
.our-product-container {
  margin-top: 50px;
}

.our-product-heading {
  text-align: start;
  font-size: 38px;
  padding-bottom: 10px;
  color: #1b3a57;
}

.our-product-card {
  border-radius: 20px;
  border: 1px solid #1b3a57;
  padding: 10px;
  background-image: url("../../Assets/SKImages/section2Bg2.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 272px;
  background-position: center;
}

.our-product-card1 {
  border-radius: 20px;
  border: 1px solid #1b3a57;
  padding: 10px;
  background-image: url("../../Assets/SKImages/section2Bg1.png");
  height: 272px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* Responsive styles for mobile and tablets */
@media (max-width: 1024px) {
  .our-product-heading {
    font-size: 32px; /* Slightly smaller heading */
  }

  .our-product-card,
  .our-product-card1 {
    padding: 15px; /* Increase padding for touch targets */
  }

  .product-name {
    padding-left: 20px; /* Align product name text better */
    margin-top: 20px; /* Adjust top margin for better spacing */
  }

  .media-button,
  .button-product {
    margin-left: 20px; /* Center buttons */
    margin-top: 10px; /* Add space above buttons */
  }
}

/* Additional responsive adjustments specifically for iPad Pro */

@media (min-width: 470px) and (max-width: 767px){
  .home-ourproduct-div{
    padding-left: 100px !important;
  }
}
@media (min-width: 768px) and (max-width: 769px){
  .home-ourproduct-div{
    padding-left: 300px !important;
  }
}
@media (min-width: 770px) and (max-width: 992px){
  .home-ourproduct-div{
    padding-left: 400px !important;
  }
}
@media (max-width: 768px) {
  .cement-img {
    width: 200px;
    height: 200px;
  }

  .product-name {
    padding-left: 179px;
  }

  .media-button {
    margin-left: 200px !important;
  }

  .button-product {
    margin-left: 175px !important;
  }

  .search-media-container {
    margin-right: 51px !important;
  }
}

.search-media-container {
  justify-content: right !important;
}

.product-name h2 {
  color: #001f3f;
  font-weight: 900;
}

.product-name p {
  color: #001f3f;
  font-weight: 700;
}

.product-name {
  margin-top: 50px;
}

/* ====our product css End======== */
/* =======awards section start======== */
.awards-image {
  background-color: #d9d9d9;
  padding: 10px;
  display: flex;
  justify-content: center;
}
.awards-image img {
  width: 150px;
  height: 150px;
  justify-content: center;
}
.awards-container {
  padding: 50px;
}
.awards-card {
  border: 1px solid #d0d0d0;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 280px;
  height: 470px;
}
.awards-card h3 {
  font-size: 24px;
}
.arrow-icons {
  font-size: 28px;
}
.arrow-icons:hover {
  color: #1b3a57;
}

@media (max-width: 768px) {
  .awards-card {
    justify-content: center;
    margin-left: 60px;
    margin-top: 20px;
  }
}

/* =======awards section end======== */

/* --------Sustanability css-------- */

.sustainability-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: start;
}
.sustain-container {
  margin-top: 50px;
}
.sustainability-col {
  flex: 1;
  padding: 20px;
}

.section-image {
  width: 400px;
  height: 400px;
  border-radius: 8px;
}

.section-head {
  color: #22324f;
  text-align: start;
}

.section-para {
  color: #424242;
  font-size: 1em;
  line-height: 1.6em;
  text-align: left;
}

.read-more {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #1b3a57;
  color: #f7f7f7;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  text-transform: uppercase;
  justify-self: start;
  text-decoration: none;
}

.read-more:hover {
  background-color: #1b3a57;
}
@media (max-width: 768px) {
  .section-image {
    width: 300px;
    /* max-width: 400px; */
    height: 300px;
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0,0,0,0.1); */
  }
  .section-head {
    padding: 0;
  }
  h4.home-banner-h4-purchase-cement {
    font-size: 17px !important;
  }
  .banner-content h2.home-banner-h2-your-trusted {
    font-size: 20px !important;
  }
  .banner-content h2.home-banner-h2-best-rates {
    font-size: 24px !important;
  }
}

/*------- clients css --------- */

.carousel-container {
  overflow: hidden;
  width: fit-content;
  margin-top: 50px;
}

.client-carousel {
  display: flex;
  animation: slide 3000ms infinite linear;
}

.client-carousel img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  margin: 0 10px;
  border: 2px solid #1b3a57;
  border-radius: 5px;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

/*--------- Testimonials css ----------*/
.test-container {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 50px;
  text-align: center;
  /* margin-top: 50px; */
}

.card-container {
  display: flex;
  justify-content: space-between;
  
}

.testimonial-card {
  background-image: url("../../Assets/SKImages/bg-blue-rect.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0 20px 0 20px;
  height: 310px;
  width: 100%;
}

@media (max-width: 1024px) {
  .testimonial-card{
    height: 360px;
    width: 100%;
  }
}

  .testimonial-body {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
}
.testimonial-card img {
  display: block;
  margin: 0 auto;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.testimonial-body {
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
}
.testimonial-card p {
  padding-top: 20px;
  font-size: 14px;
}
.test-container h2 {
  color: #001f3f;
  margin-bottom: 50px;
}

.product-card-container {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(240px, 1fr)
  ); /* Ensures responsive layout */
  gap: 20px;
  justify-content: center;
  /* padding: 20px; */
  margin-bottom: 20px;
}
.product-container {
  margin-top: 50px;
}
.product-head {
  padding-left: 20px;
  color: #001f3f;
}
.product-rating {
  margin: 10px 0;
  text-align: center;
}
.product-brand {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: bold;
  color: #000;
}
.products-card-body h2 {
  color: #1b3a57;
  font-size: 14px;
  text-align: left;
  padding-top: 8px;
}
.products-card-body h3 {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0 !important;
}
.products-card-body p {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  text-align: left;
}
.product-price {
  text-align: left;
}
.products-card-main {
  padding: 15px;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  height: autopx;
}
.products-card-main img {
  height: 200px;
}
.product-brand span {
  font-size: 16px;
  font-weight: 400;
}
.products-card-main:hover {
  background-color: #ffffff;
  box-shadow: #6f89b3 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.original-price {
  font-size: 14px;
  text-decoration: line-through;
  color: #888;
}
.view-button {
  width: 100%;
  border-radius: 10px;
  border: 1px solid;
  padding: 5px 0 5px 0;
  color: #001f3f;
}
.Heart-outlined {
  font-size: 18px;
  text-align: right;
}
/* .product-card-container h2{
    font-size: 38px;
} */

/* ---steelcarasoul css-----*/
.slick-slide {
  padding: 0 10px;
}

/*------- Recently viewed carasoul-------- */
/* HomeBanner.css */

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
}

.custom-prev {
  left: -30px;
}

.custom-next {
  right: -30px;
}

.arrow-icon {
  background-color: #d3d3d3;
  border-radius: 50%;
  padding: 10px;
  color: #1b3a57;
}

.arrow-icon:hover {
  background-color: #1b3a57;
  color: #d3d3d3;
}

.home-product-detail-discount {
  color: #fff;
  background-color: #1b3a57;
  border-radius: 15px !important;
  font-size: 12px;
  padding: 4px 5px;
}
@media (max-width: 768px) {
  .custom-prev {
    left: 0;
  }
  .custom-next {
    right: 0;
  }
  .recently-viewed-steel-card {
    width: 80% !important;
  }
  .banner1-content {
    padding: 40px;
  }
  .testimonial-card {
    height: 350px;
  }
  .products-card-main-carousel {
    background-color: #ffffff;
  }
  .products-card-main-carousel {
    width: 300px !important;
  }
}

.steel-product-card-main {
  border: none !important;
  padding: 50px !important;
}
.steel-product-card-main img {
  height: 170px !important;
}
.products-card-main-carousel {
  padding: 10px;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
}
.products-card-main-carousel:hover {
  background-color: #ffffff;
  box-shadow: #6f89b3 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

/*------ get app css------ */
.get-now-button {
  background-color: #1b3a57;
  width: fit-content;
  border-radius: 10px;
  padding: 5px 10px;
  color: #fff;
}
.app-img {
  width: 500px;
  height: 500px;
}

.slick-dots {
  position: static !important;
}
.home-ourproduct-shopbutton{
  display: flex !important;
  justify-content: center !important;
  
}
@media (max-width: 768px) {
  .app-img {
    width: 350px;
    height: auto;
  }
  .mobile-get-now {
    padding: 13px 8px !important;
  }
}
.get-app-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: start;
}

@media (max-width: 768px) {
  .recently-viewed-mob-response {
    padding-left: 23%;
  }
  .our-product-heading {
    font-size: 35px !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .home-banner-1,
  .home-banner-2,
  .home-banner-3 {
    height: 858px !important;
  }
  /* .product-name {
    padding-left: 0px !important;
  }
  .product-name {
    margin-left: 163px !important;
    padding-left: 200px !important;
  } */
  /* .media-button {
    margin-left: 380px !important;
  } */
  /* .our-product-card1 {
    height: 388px !important;
  } */
  .tab-our-product1 {
    margin-left: 80px !important;
    margin-top: 51px !important;
  }
}
.faq-get-in-touch-button {
  position: relative;
  overflow: hidden;
  background-color: #1b3a57;
  border: none;
  color: #d3d3d3;
  font-size: 16px;
  border-radius: 15px;
  padding: 5px 10px;
  cursor: pointer;
  z-index: 1;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.faq-get-in-touch-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;

  transform: scaleX(0);
  transform-origin: 0 50%;
  transition: transform 0.475s ease;
  z-index: -1;
  border: 1px solid #1b3a57;
}

.faq-get-in-touch-button:hover::before {
  transform: scaleX(1);
}

.faq-get-in-touch-button:hover {
  color: #1b3a57;
}
.add-to-cart-button {
  position: relative;
  overflow: hidden;
  background-color: #1b3a57;
  border: none;
  color: #f7f7f7;
  font-size: 16px;
  border-radius: 15px;
  padding: 5px 10px;
  cursor: pointer;
  z-index: 1;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.add-to-cart-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: #d3d3d3; /* Thicker color with reduced transparency */
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition: transform 0.475s ease;
  z-index: -1;
  border: 1px solid #1b3a57;
  color: #1b3a57;
}

.add-to-cart-button:hover::before {
  transform: scaleX(1);
}

.add-to-cart-button:hover {
  color: #1b3a57; /* Keep the original text color */
}
.no-products-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px; /* Adjust height as necessary */
  text-align: center;
}

.no-products-found h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.no-products-found p {
  font-size: 16px;
  color: #666;
}
/* 
  @media (min-width: 1600px) {
    .products-card-main{
        width: 550px !important;
    }

  }  */
