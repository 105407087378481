.contact-main{
    background-image: url(../Assets/SKImages/contact-bg.png);
    position: relative; 
    background-size: cover; 
    background-position: center;
    height: 650px;
    width: 100%;
    background-color: #F7F7F7;
    display: flex;
    justify-content: center;
    align-items: center;


}
.contact-main h1{
    padding-top: 30px;
    font-weight: bold;
}
.contact-container{
    background-color: #ffffff;
    border-radius: 10px;
    padding: 30px;
    width: 50%;
}
.contact-button{
    background-color: #1B3A57;
    border: 2px solid #1B3A57;
    width: 100%;
    height: auto;
    /* padding: 10px; */
}
.contact-button:hover{
    color: #F7F7F7 !important;
    background-color: #1B3A57 !important;
    border: 2px solid #1B3A57;
    box-shadow: 8px 6px 16px -5px #27303f, 0 10px 16px -8px #0000004d;
}
.contact-main p{
    margin: 0;
}
.contact-banner-row{
    background-image: url("../Assets/SKImages/contactBanner.png");
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
}
.banner-content-contact{
    padding-top: 100px;
    text-align: left;
}
.banner-content-contact h2{
    color: #1B3A57;
    font-weight: 400;
    font-size: 35px;
    letter-spacing: 1px;
}
.banner-content-contact h1{
    color: #1B3A57;
    font-weight: 700;
    font-size: 48px;
    letter-spacing: 1px;
}
.banner-content-contact span{
    color:#6a6a6c;
}
.banner-content-contact p{
    color:#fff;
    font-size: 18px;
    letter-spacing: 0.8px;
}

/* ---ContactInfo css -------*/

.info-container {
    margin-top: 50px;
    margin-bottom: 50px;
    border: 2px solid #F7F7F7;
    border-radius: 10px;
    padding: 20px;
}

.contact-section {
    border-right: 2px solid #F7F7F7;
    padding: 0 20px;
}

.contact-section:last-child {
    border-right: none;
}

.contact-icons {
    width: 50px;
    display: block;
    margin-bottom: 10px;
    justify-content: left;
}

.contact-section h5, .contact-last-section h5 {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
}

.contact-section p, .contact-section ul {
    margin: 0;
    margin-bottom: 10px;
    line-height: 1.5;
    text-align: left;
}
.contact-section ul{
    padding-left: 20px;
    
}


.contact-social-icons{
    display: flex;
    margin: 0 !important; 
    margin-left: -50px !important;
}

.contact-link {
    color: #1B3A57;
    font-size: 24px;
}
.contact-section h6{
    font-size: 14px;
    color: #59595F;
    text-align: left;
}
.contact-last-section, .contact-section{
    padding-left: 40px;
}
.contactForm-input::placeholder,
textarea::placeholder {
  color: rgb(214, 214, 214) !important; /* Adjust to the shade of gray you prefer */
}

/* Responsive Styles for Mobile Devices */
@media (max-width: 768px) {
    .contact-container{
        width: 90%;
        /* margin: 5px; */
    }
    .contact-section {
        border-right: none;
        
    }
    .info-container {
        flex-direction: column;
        align-items: center;
        width: 90%;
    }

    .contact-section, .contact-last-section {
        width: 100%;
        margin-bottom: 20px;
    }

    iframe {
        width: 100%;
        height: 300px;
    }
    .media-banner-contact p{
        font-size: 14px !important;
    }

    .banner-content-contact, .home-banner-1{
        padding-left: 20px !important;
    }
    
    .banner-content-contact h2{
        font-size: 20px !important;
    }
    .banner-content-contact h1{
        font-size: 25px !important;
    }
    
    .banner-content-contact h4{
        font-size: 30px !important;
        padding-top: 30px;
    }
    .banner-content-contact p{
        font-size: 16px;
    }
  
    .banner-content-contact{
        padding: 60px 40px !important;
    }
    

}
@media (max-width: 1024px) {
    .banner-content-contact{
        padding-top: 10px !important;
    }
}
@media (max-width: 350px) {
    .banner-content-contact{
        padding-top: 0px !important;
    }
}
