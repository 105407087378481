.shop-banner-container {
  width: calc(100% - 40px);
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}
.shop-banner-row {
  background-image: url("../../Assets/SKImages/shop-banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 400px;
  display: flex !important;
  /* overflow: hidden; */
}

.shop-banner {
  width: 100%;
  display: block;
}

.shop-container {
  margin: 20px auto;
  padding: 20px;
}

.shop-product-row {
  display: flex;
}

/* .col-lg-3, .col-lg-9 {
    padding: 0 15px;
} */

.shop-left-container {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.filter-section {
  margin-bottom: 20px;
  border: 1px solid #1b3a57;
  border-radius: 10px;
  padding: 15px;
}

.filter-section h4 {
  margin-bottom: 10px;
  font-size: 18px;
  text-align: left;
}
.filter-section a {
  color: #303030;
  text-align: none !important;
}

.filter-section ul {
  list-style-type: none;
  padding-left: inherit;
}

.filter-section li {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.filter-section li input {
  margin-right: 10px;
}

.filter-section .star-rating {
  font-size: 16px;
  color: #ffd700;
}

.products-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.products-head {
  margin-bottom: 20px;
}

.products-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-around;
  width: 100%;
  gap: 20px;
}

/* .products-card-main {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transition: box-shadow 0.3s ease;
} */

/* .products-card-main:hover {
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
} */

.Heart-outlined {
  font-size: 15px;
  text-align: right;
}

.discount-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #ff4d4f;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}

.products-image {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.products-card-body {
  text-align: center;
  /* padding:0 20px  */
}

.products-rating {
  margin-bottom: 10px;
}

.products-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.products-price {
  font-size: 16px;
  font-weight: 500;
  color: #555;
}

.price {
  color: #ff4d4f;
}

.original-price {
  text-decoration: line-through;
  color: #888;
}

.products-brand {
  font-size: 14px;
  color: #777;
}

.add-to-cart-button {
  background-color: #1b3a57;
  border: none;
  color: #f7f7f7;
  font-size: 16px;
  border-radius: 12px;
  padding: 5px 10px;
}
:where(.css-dev-only-do-not-override-17seli4).ant-rate {
  font-size: 18px;
}
.ant-slider .ant-slider-track,
.ant-slider:hover .ant-slider-track {
  background-color: #1b3a57 !important;
}

:where(.css-dev-only-do-not-override-17seli4).ant-slider
  .ant-slider-handle::after,
:where(.css-dev-only-do-not-override-17seli4).ant-slider:hover
  .ant-slider-handle::after {
  box-shadow: 0 0 0 3px #1b3a57;
}

.shop-button:hover {
  color: #1b3a57 !important;
  border-color: #1b3a57 !important;
}

.price-range-container {
  position: relative;
  height: 30px;
}

.form-range {
  /* -webkit-appearance: none; */
  width: 100%;
  height: 8px;
  background: #ddd;
  border-radius: 5px;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.15s ease-in-out;
  position: absolute;
  margin-left: -130px;
}

.form-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background: #1b3a57;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
}

.form-range::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: #004080;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
}

.price-inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.price-input {
  width: 70px;
  padding: 3px;
  border: 1px solid #ccc;
  border-radius: 10px;
  text-align: center;
}
.input-checkbox {
  background-color: #ce1b28 !important;
}

/* / pagination.css / */
.shop-pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.shop-pagination .ant-pagination-item {
  border: none;
  margin: 0 5px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.shop-pagination .ant-pagination-item a {
  color: #1b3a57;
}

.shop-pagination .ant-pagination-item-active {
  background-color: #1b3a57;
}

.shop-pagination .ant-pagination-item-active a {
  color: #fff;
}

.shop-pagination .ant-pagination-item:hover {
  background-color: #d9d9d9;
}

.shop-pagination .ant-pagination-prev,
.shop-pagination .ant-pagination-next {
  border: none;
  background: none;
}

.shop-pagination .ant-pagination-prev .ant-pagination-item-link,
.shop-pagination .ant-pagination-next .ant-pagination-item-link {
  color: #1b3a57;
}

.shop-pagination .ant-pagination-prev:hover .ant-pagination-item-link,
.shop-pagination .ant-pagination-next:hover .ant-pagination-item-link {
  color: #1b3a57;
}

.faq-get-in-touch-button-review {
  position: relative;
  overflow: hidden;
  font-size: 16px;
  color: #ffffff;
  padding: 8px 8px !important;
  margin-top: 2px;
  border-radius: 1rem;
  background-color: #1b3a57;
  /* width: -moz-fit-content; */
  /* width: fit-content; */
  cursor: pointer;
  z-index: 1;
}
@media (max-width: 768px) {
  .shop-banner-container {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .shop-banner {
    height: 150px;
  }
  .products-card-container {
    grid-template-columns: 1fr;
  }
  .filter-buttons-container {
    flex-direction: column;
    gap: 20px;
  }
  .banner-content h4 {
    font-size: 15px;
  }
  .banner-content h2 {
    font-size: 25px !important;
  }
  .banner-content p {
    font-size: 13px !important;
  }
  .explore-button-shop {
    margin-bottom: 20px !important;
  }
  .banner-header-returns {
    font-size: 42px !important;
  }
}

/* -----------product image css------------- */

.product-detail-main {
  display: flex;
  margin-top: 80px;
  margin-bottom: 50px;
  justify-content: space-evenly;
}

.product-images {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 20px;
  /* margin-top: 50px; */
}

.small-images {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-right: 20px;
}

.small-images img {
  width: 140px;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
}

.small-images img.active {
  /* border: 3px solid #1b3a57; */
}

.product-left-detail {
  background-color: #fff;
  border-radius: 10px;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
}

.product-left-detail img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  padding: 40px 20px 20px;
}

.product-detail-info {
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 400px;
}

.product-detail-category {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
}

.product-detail-category h2 {
  font-size: 24px;
  margin: 10px 0;
}

.product-detail-description,
.bootom-line-section p {
  font-size: 16px;
  color: #666;
  /* margin-bottom: 20px; */
}
.bootom-line-section {
  border-bottom: 1px solid #d3d3d3;
}

.product-detail-price {
  margin: 20px 0px 5px 0px;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.product-current-price {
  color: #000;
  font-weight: bold;
  margin-right: 10px;
}

.product-original-price {
  text-decoration: line-through;
  color: #999;
  margin-right: 10px;
}

.product-detail-discount {
  color: #fff;
  background-color: #1b3a57;
  border-radius: 20px;
  font-size: 12px;
  padding: 5px 10px;
}

.product-delivery-info {
  font-size: 14px;
  margin: 10px 0;
  line-height: normal;
}
.product-delivery-info p {
  margin-bottom: 5px !important;
}

.quantity-selector {
  display: flex;
  align-items: center;
  background-color: #d3d3d3;
  border-radius: 25px;
}

.quantity-button {
  width: 30px;
  /* height: 30px; */
  border: none;
  background-color: #d3d3d3;
  font-size: 18px;
  cursor: pointer;
  /* border-radius: 50%; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 0 5px; */
}

.quantity-value {
  width: 40px;
  text-align: center;
  font-size: 18px;
}

.product-add-to-cart {
  padding: 10px 20px;
  background-color: #1b3a57;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 25px;
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.product-add-to-cart:hover {
  color: #1b3a57;
}

.product-add-to-cart::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: linear-gradient(82.3deg, #d3d3d3, #d3d3d3);
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition: transform 0.475s ease;
  z-index: -1;
}

.product-add-to-cart:hover::before {
  transform: scaleX(1);
}

.discount-main {
  margin-top: 45px;
}
.voucher-bg {
  background-image: url(../../Assets/SKImages/vocher-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-shadow: #1b3a57;
  width: 250px;
  /* padding: 10px; */
}

.line-para {
  border-bottom: 1px dashed #1b3a57;
  width: 85%;
  margin: auto;
  padding: 10px;
}

.detail-info-icon {
  margin-right: 0;
}

.d-flex.justify-content-between {
  width: 100%;
}
.product-image-gst {
  font-size: 13px;
  color: #706f6f;
  margin-top: 0px;
  margin-bottom: 10px;
}
.product-image-gst-steel {
  font-size: 15px;
  color: #706f6f;
  margin-top: 0px;
  margin-bottom: 10px;
}

.Map-button {
  color: #fff;
  background-color: #1b3a57;
  border-radius: 8px;
  padding: 5px 8px;
}
.Map-button:hover {
  background-color: #264c70;
}
.map-search-input {
  width: 400px;
  margin-right: 10px;
  border-radius: 8px;
  padding: 5px 8px;
  border: 1px solid #1b3a57;
}
.map-search-input:focus {
  border: 1px solid #1b3a57;
  outline: none;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .product-detail-main {
    flex-direction: column;
    padding-left: 50px;
  }
}
@media (max-width: 768px) {
  .product-detail-main {
    flex-direction: column;
    margin: 0 10px;
  }
  .product-images {
    align-items: center;
    flex-direction: column-reverse;
    gap: 20px;
  }
  .discount-main {
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
    align-items: center;
    gap: 15px;
  }
  .mobile-promo {
    display: flex;
    justify-content: center !important;
  }
  .product-review-container {
    padding: 0 !important;
  }
  .heart-mobile {
    margin-top: -170px !important;
  }

  .map-search-input {
    width: 190px !important;
  }
}

/* ProductReview.css */

.product-review-container {
  padding: 60px;
}

.product-review-menu-container {
  text-align: center;
  margin-bottom: 20px;
}
.product-review-menu {
  display: flex;
  font-size: 20px;
  justify-content: center;
}

.product-review-menu .ant-menu-horizontal {
  border-bottom: none;
}

.product-review-menu .ant-menu-horizontal > .ant-menu-item {
  padding: 0 20px;
  font-size: 20px !important;
  color: #d3d3d3;
}

.product-review-menu .ant-menu-horizontal > .ant-menu-item::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: transparent;
  transition: width 0.3s;
  /* margin: auto; */
}

.product-review-menu .ant-menu-horizontal > .ant-menu-item-selected::after {
  width: 100%;
  background: #1b3a57 !important;
}

.product-review-menu .ant-menu-item-selected {
  color: #000 !important; /* Change the color of the selected item */
}

.product-review-content {
  text-align: left;
  font-size: 16px;
}

:where(.css-dev-only-do-not-override-17seli4).ant-menu-light.ant-menu-horizontal
  > .ant-menu-item-selected::after,
:where(.css-dev-only-do-not-override-17seli4).ant-menu-light.ant-menu-horizontal
  > .ant-menu-item:hover::after {
  border-color: #1b3a57;
}
.write-button {
  background-color: #1b3a57;
  color: #fff;
  border-radius: 20px;
  border-color: #1b3a57;
}
.drop-latest-button {
  border-radius: 20px;
}

.view-detail-button {
  border-radius: 25px;
  border-color: #1b3a57;
  padding: 10px 30px;
}
.review-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.review-form h2 {
  color: #1b3a57;
  padding-top: 30px;
}
.review-form p {
  color: #000;
  padding-top: 10px;
  text-align: center;
}
.review-form img {
  width: 100px;
  height: 100px;
  justify-content: center;
  align-content: center;
}
.explore-button-shop {
  position: relative; /* To contain the pseudo-element */
  overflow: hidden; /* Ensure the pseudo-element doesn't overflow */
  padding: 8px 20px; /* Adjust padding for consistent size */
  color: #ffffff;
  border: 1px solid #fff;
  border-radius: 5px;
  background-color: #1b3a57;
  cursor: pointer;
  transition: color 0.475s ease; /* Smooth transition for text color */
  z-index: 1; /* Keep the text above the pseudo-element */
}

.explore-button-shop::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: #d3d3d3;
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition: transform 0.475s ease;
  z-index: -1;
}

.explore-button-shop:hover::before {
  transform: scaleX(1);
}

.explore-button-shop:hover {
  color: #1b3a57;
}

.dropdown-trigger {
  color: #333;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 4px !important;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.ProjectReviews-delete-button{
  color: red !important;
  gap: 10px !important;
}

/* shop product.... */
@media (max-width: 768px) {
  .media-shop-product {
    flex-direction: column;
  }
  .products-card-main {
    width: 280px !important;
    margin: auto;
  }
  .media-product-button {
    display: flex;
    gap: 20px;
    width: 100px;
  }
  .product-left-detail {
    width: 258px !important;
    /* margin-top: 109px !important; */
  }
  .product-review-content h2 {
    font-size: 18px !important;
  }
  .media-productreview {
    flex-direction: column;
    gap: 12px !important;
  }
  .small-images {
    flex-direction: row;
    gap: 15px !important;
  }
  .small-images img {
    width: 100px !important;
    height: 100px !important;
  }
  .product-review-menu-container
    :where(.css-dev-only-do-not-override-17seli4).ant-menu-light {
    width: 252px !important;
  }
  .card-new-steel {
    width: auto !important;
    padding: 20px 20px 10px 5px !important;
  }
  .media-heart-outlined {
    position: relative;
    top: 50px !important;
    left: 115px !important;
  }
  .header-new-steel,
  .row-new-steel {
    gap: 10px !important;
  }
}

.card-new-steel {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px 30px 10px 20px;
  width: 450px;
  margin: auto;
}

.header-new-steel,
.row-new-steel {
  display: flex;
  /* justify-content: space-between; */
  gap: 25px;
  margin-bottom: 10px;
  text-align: center;
}

.header-new-steel p,
.row-new-steel p {
  margin: 0;
  flex: 1;
}

.row-new-steel input {
  width: 80px;
  border-radius: 8px;
  padding: 0px 5px 0px 5px;
  border: 1px solid #1b3a57;
}
.row-new-steel p,
.row-new-steel input {
  white-space: nowrap;
  overflow-x: auto;
  display: inline-block;
  width: 80px;
}
.total-order-quantity-div p {
  white-space: nowrap;
  overflow-x: auto;
  display: inline-block;
  width: 80px;
}
.row-new-steel p::-webkit-scrollbar,
.row-new-steel input::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.total-order-quantity-div p::-webkit-scrollbar,
.total-order-quantity-div input::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.show-more-coupons {
  background-color: #fff;
  border: none;
  color: #1b3a57;
}
.width-slider {
  width: 47%;
}
.customs-prev {
  left: -34px;
}
.customs-next {
  right: -34px;
}

.sticky-product-sidebar {
  position: sticky;
  top: 20px; /* Adjust this value to control the distance from the top */
}

.product-buy-now {
  position: relative;
  overflow: hidden;
  font-size: 16px;
  color: #ffffff;
  padding: 10px 20px;
  margin-top: 2px;
  /* margin-bottom: 20px; */
  border-radius: 1.5rem;
  background-color: #1b3a57;
  width: fit-content;
  border: 1px solid #1b3a57;
  cursor: pointer;
  z-index: 1;
}

.product-buy-now:hover {
  color: #1b3a57;
}

.product-buy-now::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: linear-gradient(82.3deg, #d3d3d3, #d3d3d3);
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition: transform 0.475s ease;
  z-index: -1;
}

.product-buy-now:hover::before {
  transform: scaleX(1);
}

.product-buy-now-steel {
  padding: 10px 20px;
  background-color: #1b3a57;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 25px;
  width: 40%;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.product-buy-now-steel:hover {
  color: #1b3a57;
}

.product-buy-now-steel::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: linear-gradient(82.3deg, #d3d3d3, #d3d3d3);
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition: transform 0.475s ease;
  z-index: -1;
}

.product-buy-now-steel:hover::before {
  transform: scaleX(1);
}

.product-add-to-card-steel {
  padding: 10px 25px;
  background-color: #d3d3d3;
  color: #1b3a57; /* Text color */
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 20px;
  width: 40%;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.product-add-to-card-steel:hover {
  color: #d3d3d3; /* Text color on hover */
}

.product-add-to-card-steel::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: linear-gradient(
    82.3deg,
    #1b3a57,
    #1b3a57
  ); /* Linear gradient background */
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition: transform 0.475s ease;
  z-index: -1;
}

.product-add-to-card-steel:hover::before {
  transform: scaleX(1); /* Apply the scaling effect on hover */
}

.section-para-space p {
  line-height: 2 !important;
}
.section-para-space li {
  line-height: 2 !important;
}

.centered-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 800px;
}
@media (min-width: 1600px) {
  .products-card-body {
    padding: 0 40px;
  }
}
.banner-content-shop {
  padding-top: 100px;
  text-align: left;
}
.banner-content-shop h2 {
  color: #1b3a57;
  font-weight: 400;
  font-size: 35px;
  letter-spacing: 1px;
}
.banner-content-shop h1 {
  color: #1b3a57;
  font-weight: 700;
  font-size: 48px;
  letter-spacing: 1px;
}
.banner-content-shop span {
  color: #6a6a6c;
}
.banner-content-shop p {
  color: #fff;
  font-size: 20px;
  letter-spacing: 0.8px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .banner-content-shop,
  .home-banner-1 {
    padding-left: 20px !important;
  }

  .banner-content-shop h2 {
    font-size: 20px !important;
  }
  .banner-content-shop h1 {
    font-size: 25px !important;
  }

  .banner-content-shop h4 {
    font-size: 30px !important;
    padding-top: 30px;
  }
  .banner-content-shop p {
    font-size: 16px;
  }

  .banner-content-shop {
    padding: 10px 20px !important;
  }
}
