.profile-sidebar{
    width: 300px;
    position: sticky;
        top: 100px; 
        height: calc(100vh - 100px);
        overflow: auto;
    }
    .profile-sidebar h2{
        color: #1b3a57;
        font-weight: 700;
        font-size: 28px;
        padding: 0px 0 0px 10px;
        text-align: left;
        border-left: 4px solid #1b3a57;
        margin-bottom: 20px;
    
    }
    .profile-sidebar h4{
        color: #807d7e;
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 20px;
        text-align: left;
    }
    .profile-page{
        padding-top: 80px;
    }
    .profile-sidebar ul{
        padding-left: 0;
    }
    .profile-sidebar ul li{
        list-style: none;
        padding:12px 20px;
    }
    .profile-sidebar ul li a{
        text-decoration: none;
        color: #807d7e;
        font-size: 18px;
        display: flex;
        gap:10px;
        text-align: left;
        align-items: center;
    }
    /* .profile-sidebar ul li a:hover{
       background-color: #f6f6f6;
       border-left: 2px solid #1b3a57;
       padding: 12px 20px;
    } */
    .profile-sidebar ul li a:hover{
        color: #1b3a57;
    }
    .profile-sidebar .active-link {
        background-color: #f6f6f6;
        border-left: 2px solid #1b3a57;
        padding: 12px 20px;
    }
    @media (max-width: 1024px)  {
        .profile-sidebar ul{
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        .profile-sidebar{
            width: 100%;
            height: 250px;
            background-color: #fff;
            position: relative;
            z-index: 10;
            top: 20px;
            /* padding-top: 30px; */
        }
        .profile-page{
            padding-top: 0;
        }
        .profile-sidebar h2 {
            margin-bottom: 0;
        }
        .profile-sidebar h4 {
            margin-bottom: 10px;
            margin-top: 10px;
        }
        .profile-sidebar .active-link{
            padding: 8px 10px;
        }

    }
   