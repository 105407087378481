.header-icons {
  font-size: 18px;
  color: #1b3a57;
  border: 1px solid #1b3a57;
  border-radius: 50%;
  padding: 12px;
}
.header-icons-phone {
  font-size: 45px;
  color: #1b3a57;
  border: 1px solid #1b3a57;
  border-radius: 50%;
  padding: 12px;
}
.header-contact-details h3 {
  font-size: 16px;
  font-weight: 700;
}
.header-contact-details p {
  font-size: 16px;
}
.header-logo {
  width: 160px;
  height: 76px;
}
.header-details-row {
  background-image: url("../../Assets/SKImages/header-logo.png");
  background-size: cover;
  height: 164px;
}
div.container.header-menu {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0;
  /* width: 1200px;
  margin-left: 60px; */
}
a.header-menu-items {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  background-color: transparent;
  border-radius: 30px;
}
a.header-menu-items:hover {
  background-color: #1b3a57;
  color: #fff;
  border-radius: 30px;
}
a.header-menu-items:active {
  background-color: #1b3a57;
  color: #fff;
  border-radius: 30px;
}
.request-quote-button {
  background-color: #1b3a57;
  padding: 12px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-right: -20px;
  color: #fff;
  border: 1px solid #1b3a57;
}
.triangle {
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-left: 50px solid #d3d3d3;
  transform: rotate(270deg);
  margin-left: -50px;
}
.mobile-header {
  display: none;
}
.desktop-header {
  padding: 0px 0px;
  background-color: #fff;
  z-index: 1;
}
.search-input-table {
  border: 1px solid #e5e5e5 !important;
  border-radius: 30px !important;
  color: #878790 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 8px 12px !important;
  width: 400px;
}
.search-button-header button {
  border: 0;
  padding: 8px 12px;
  color: #fff;
  background: #1b3a57;
  font-size: 14px;
  font-weight: 600;
  margin-left: -45px;
  margin-top: 3px;
  border-radius: 50%;
}
.profile-icon-header {
  /* background-color: #1b3a57; */
  border-radius: 50%;
  padding: 4px;
  font-size: 18px;
  color: #fff;
}
.wishlist-icon-header {
  background-color: #fff;
  border-radius: 50%;
  padding: 8px;
  font-size: 18px;
}
.cart-icon-header {
  background-color: #1b3a57;
  border-radius: 50%;
  padding: 8px;
  font-size: 18px;
  color: #fff;
}
#navbarDropdown.all-categories-header {
  background-color: #fff;
  border-radius: 30px;
  padding: 10px 25px !important;
  color: #000;
  font-weight: 400;
  border: none;
}
.wishlist-icon-mobile-header {
  background-color: #fff;
  border: 1px solid #1b3a57;
  border-radius: 50%;
  padding: 8px;
  font-size: 18px;
}
.sticky-header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #e6effd;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  transform: translateY(-100%);
}

.sticky-header.visible {
  transform: translateY(0);
}

.header-desktop {
  width: 584px !important;
  gap: 42px !important;
}

.header-sticky {
  width: 580px !important;
  gap: 42px !important;
}
.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: #1b3a57 !important;
}
.wishlist-cart-mobile {
  padding: 10px 25px !important;
}

@media (max-width: 1024px) {
  .desktop-header {
    display: none;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: column !important; 
}
  .mobile-header {
    display: block;
    border-bottom: 2px solid #1b3a57;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 99;
  }
  .navbar {
    padding: 0;
  }
  .sticky-header {
    display: none;
  }
}
.mobile-header .navbar {
  padding: 0px;
}

.mobile-header .header-menu-items {
  color: #000;
  padding: 10px 25px;
}

.mobile-logo {
  width: 150px;
  padding: 10px;
}
a.navbar-brand {
  padding: 0;
}
.menu-button {
  background-color: #fff;
  border: none;
  color: #d3d3d3;
  font-size: 24px;
}
.menu-button.open {
  transform: rotate(90deg);
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 900;
  display: none;
}

.menu-overlay.open {
  display: block;
}

.menu-content {
  position: fixed;
  top: 0;
  right: -280px;
  width: 280px;
  height: 100%;
  background-color: #fff;
  z-index: 1000;
  overflow-y: auto;
  transition: right 0.3s ease;
}

.menu-content.open {
  right: 0;
  padding: 50px 30px 30px 30px;
  text-align: start;
}
.close-button {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1100;
}
.request-quote-button-mobile {
  background-color: #1b3a57;
  padding: 10px;
  border-radius: 10px;
  border: none;
  color: #fff;
}
.nav-link:focus {
  color: #fff !important;
}

.ant-input-search
  :where(.css-dev-only-do-not-override-17seli4).ant-input-group
  .ant-input-affix-wrapper:not(:last-child) {
  background-color: #d3d3d3 !important;
  border: 1px solid #1b3a57;
  color: #1b3a57;
}
:where(.css-dev-only-do-not-override-17seli4).ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  background-color: #d3d3d3 !important;
  border: 1px solid #1b3a57;
}
:where(.css-dev-only-do-not-override-17seli4).ant-input-affix-wrapper
  > input.ant-input {
  color: #1b3a57 !important;
  padding-left: 9px !important;
}
.active {
  /* background-color: #1b3a57; */
  color: #fff;
  border-radius: 30px;
}
li.nav-item.active a {
  color: #fff;
}
.Homepredicted-search-card,
.Homepredicted-search-card-city {
  position: relative;
}

.Homepredicted-search-card ul {
  position: absolute !important;
  top: 80%;
  left: -410px;
  background: #fff;
  border-radius: 12px;
  padding: 12px;
  width: 380px;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  z-index: 999;
}

.Homepredicted-search-card ul::-webkit-scrollbar {
  width: 4px;
}

.Homepredicted-search-card ul::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.Homepredicted-search-card ul::-webkit-scrollbar-thumb {
  background: #1b3a57;
  border-radius: 4px;
}

.Homepredicted-search-card ul::-webkit-scrollbar-thumb:hover {
  background: #555;
}

ul .predicted-search-link {
  list-style: none;
  cursor: pointer;
}

.predicted-search-link {
  /* border: 1px solid #f4f4f4; */
  /* border-radius: 12px; */
  padding: 4px 12px;
  margin: 4px 0;
  color: #1b3a57;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-align: left;
}

.nav-link.header-menu-items.active {
  /* font-weight: bold; */
  color: #fff;
  background-color: #1b3a57;
  width: fit-content;
}
.navbar-nav .nav-link.active {
  color: #fff !important;
}
ul.navbar-nav.header-desktop {
  display: flex;
  gap: 20px;
}
ul.navbar-nav.navbar-right {
  gap: 20px;
}
@media (min-width: 1600px) {
  .Homepredicted-search-card ul {
    left: -460px;
  }
}

@media (max-width: 1024px) {
  .mobile-header .header-menu-items {
    padding: 10px 24px !important;
  }
}

@media (min-width: 1500px) {
  .navbar-expand-lg {
    width: 1400px;
  }
  ul.navbar-nav.navbar-right {
    gap: 40px;
  }
  .header-sticky {
    width: 700px !important;
    gap: 60px !important;
  }
  .header-desktop {
    width: 700px !important;
    gap: 60px !important;
  }
}
