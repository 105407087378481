/*orderpage.css*/

.my-orders-menu .ant-menu-item {
  margin-right: 230px;
  justify-content: space-around;
  /* position: sticky !important;
  top: 100 !important; */

}

.my-orders h4 {
  text-align: start;
  margin-bottom: 20px;
}

.order-header {
  /* background-color: #f6f6f6; */
  text-align: left;
  width: auto;
  /* padding: 20px; */
}

.ant-divider-horizontal.devider {
  margin: 8px 0px 8px 0px !important;
}

/* .order-cards{
  
  
} */
.order-content p,
.order-content h3 {
  margin-bottom: 1px;
  text-align: left;
}

.order-content {
  text-align: justify;
}

.order-shipped {
  text-align: right;
}

.product-details {

  flex-direction: column;
  text-align: right;
  margin: 0%;
}

.order-content p {
  /* padding-left: 20px; */
  text-align: left;
}

.my-orders-menu .ant-menu-item-selected {
  background-color: #1b3a57 !important;
  color: white !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

/* .explore-button {
  background-color:#1b3a57;
} */
.order-time p {
  margin-bottom: 0;
}

.order-cards p {
  margin-bottom: 0;

}

.my-orders-product-img {
  width: 120px;
  height: 120px;
}



/*Orderdetails.css*/
.order-details-container {
  text-align: start;
}

.card-bg-detail {
  background-color: #f6f6f6;
  border-left: #f6f6f6;
  width: auto;
  margin-bottom: 40px;
}

.order-address {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #d3d3d3;
  padding-left: 80px;
  margin-top: 20px;
}

.order-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.detail-content {
  text-align: justify;
  padding-left: 60px;
}

.details-placed {
  background-color: #d3d3d3;
  text-align: center;
  width: 600px;
}

.order-steps {
  padding: 20px 80px;
}

.horizontal-line {
  border: 1px solid #f6f6f6;
  margin-top: 10px;
}

.order-info p {
  margin-bottom: 0;
}

.quality-details {
  display: flex;
}

.quality-detail {
  display: flex;
}

.myOrders-details-div {
  padding: 15px 10px 10px 15px;
  border: 1px solid #e1e1e1;
  border-radius: 7px;
  background-color: #FFFFFF;
  box-shadow: #96a8c5 0px 1px 10px -5px, rgba(0, 0, 0, 0.3) 1px 3px 8px -10px;
}

.myOrders-details-div-download-link {
  text-align: center;
  padding: 5px;
  border: 1px solid #e1e1e1;
  border-top: none;
  border-radius: 7px;
  background-color: #FFFFFF;
  box-shadow: #96a8c5 0px 1px 10px -5px, rgba(0, 0, 0, 0.3) 1px 3px 8px -10px;
}

.orderDetails-review-button {
  width: fit-content !important;
  height: fit-content !important;
}

.media-button-orderspage {
  flex-direction: column;
  gap: 20px;
}

.orderspage-buttons-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ordersPage-cancel-button {
  border: none;
  color: red;
  font-weight: 500;
  background: transparent;
  transition: all 0.6s ease;

}

.ordersPage-cancel-button:hover {
  box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
  transform: scale(1.05);
  border-radius: 12px;
  padding: 5px;
  background-color: #ffffff;
}

.ordersPage-proceedPayment-button {
  background-color: #02528b;
  border: 0;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  /* font-family: system-ui,-apple-system,system-ui,"Segoe UI",Roboto,Ubuntu,"Helvetica Neue",sans-serif; */
  font-size: 16px;
  /* font-weight: 600; */
  outline: 0;
  padding: 5px 8px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.ordersPage-proceedPayment-button:before {
  background-color: initial;
  background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
  border-radius: 12px;
  content: "";
  height: 50%;
  left: 4%;
  opacity: .5;
  position: absolute;
  top: 0;
  transition: all .3s;
  width: 92%;
}

.ordersPage-proceedPayment-button:hover {
  box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
  transform: scale(1.05);
}

.three-dots {
  cursor: pointer;
  margin-top: -70px;
}

.ant-dropdown .ant-dropdown-menu {
  margin-top: -50px !important;
}
@media (max-width: 1024px) {
  .my-orders {
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .order-steps {
    padding: 50px;
    align-items: center;
  }

  .card-bg-detail {
    margin: 0 !important;
  }

  .details-placed {
    background-color: #d3d3d3;
    text-align: center;
    width: 300px;
    margin-bottom: 10px;
    margin-top: 50px;
  }

  .details-border {
    flex-direction: column;
  }

  .detail-content {
    padding-left: 10px;
  }

  .order-address {
    align-items: center;
    padding-left: 30px;
    margin-top: 20px;
  }

  .quality-details {
    flex-direction: column;
    text-align: center;
  }

  .quality-detail {
    margin-right: 200px;
  }

  .detail-content {
    padding-left: 60px;
  }

  .quality-order h4 {
    padding-right: 100px;
  }

  .detail-images {
    padding-left: 50px;

  }

  .quality-detail h4 {
    padding-left: 100px;
  }

  /* :where(.css-dev-only-do-not-override-17seli4).ant-menu-light{
          width: 130px !important;
        } */
  .button-direction-orders {
    flex-direction: column !important;
  }

  .order-header {
    flex-direction: column !important;

  }

  .order-cards {
    /* padding-left: 28px !important; */
    margin-top: 0px !important;
    text-align: left;
    /* padding-bottom: 21px !important; */
  }

  .order-time {
    /* padding: 30px 30px 0px 30px; */
    padding-right: 0px !important;
  }

  .order-steps.ant-steps.ant-steps-vertical>.ant-steps-item:not(:last-child)>.ant-steps-item-container>.ant-steps-item-tail {
    height: 20px;
    left: -40px;
    transform: rotate(180deg);
    margin: 70px;
  }

  .order-steps.ant-steps .ant-steps-item-container {
    width: 150px !important;
    margin-bottom: 20px;
  }

  /* .order-steps.ant-steps.ant-steps-vertical{
        margin-left: 60px;
       } */

  .ordersPage-div-p p {
    font-size: 15px !important;
  }

  .my-orders h4 {
    margin-top: 20px !important;
  }

  .ordersPage-imageGap-mobile {
    gap: 5px !important;
  }

  .my-orders-product-img {
    width: 100px;
    height: 100px;
  }

  .media-button-orderspage {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-top: 20px;
    flex-direction: row;
  }

  .ordersPage-proceedPayment-button {
    margin-top: 15px !important;
  }

  .my-orders-menu {
    width: 150px;
  }

  .myOrders-details-div{
    padding: 15px 0px 10px 15px;
  }
  .product-detail-discount{
    padding: 3px 3px;
    border-radius: 10px;
  }
  .ordersDetails-mobile{
    padding-left: 3px;
  }
  .ordersDetails-mobile2{
    padding-left: 2px;
  }
}

.order-steps.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  top: -15px;
}

.order-steps.ant-steps .ant-steps-item-title::after {
  top: -15px;
}

.order-steps.ant-steps .ant-steps-item-container {
  gap: 0px
}

.order-steps.ant-steps .ant-steps-item-process .ant-steps-item-content>.ant-steps-item-title {
  font-size: 15px;
}

.my-orders-steel-table {
  justify-content: center;
  background-color: #f6f6f6;
}

.my-orders-steel-table.table>:not(caption)>*>* {
  background-color: #f6f6f6;
}

.ant-popover .ant-popover-inner-content {
  font-weight: 600;
}

.ant-popover {
  z-index: 1;
}


/* .my-orders-menu{
      position: sticky !important;
      top: 500 !important;
      z-index: 1000;
    } */