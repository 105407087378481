.add-address-button{
    background-color: #fff;
    border:none;
}
.blue-dot{
    background-color:#1b3a57;
    border-radius: 50%;
    width: 14px;
    height: 14px;
}
.delivery-address-details h4{
    color:#1b3a57;
    font-size: 18px;
    text-align: start;
}
.delivery-address-details h3{
    color:#000;
    font-size: 22px;
    text-align: start;
}
.delivery-address-details p{
   color: #4d4d4d;
   font-size: 16px;
   text-align: start;
   margin-bottom: 0;
}
.delivery-address-details-button{
    border: 1px solid #4d4d4d;
    background-color: #fff;
    border-radius: 10px;
    color:#4d4d4d;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.remove-button{
    background-color: #fff;
    color:#4d4d4d;
    border:none;
    border-right: 1px solid #4d4d4d;
    font-size: 18px;
    font-weight: 700;
    padding-right: 10px;
}
.edit-button{
    background-color: #fff;
    color:#4d4d4d;
    border:none;
    
    font-size: 18px;
    font-weight: 700;
}
.MyDetails-container{
    margin: 20px 0 50px 0;
}
.form-control.my-details-form {
    border-bottom: 1px solid #4d4d4d;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    position: relative;
    
}
.form-control.my-details-form:focus {
    border-bottom: 1px solid #4d4d4d;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    box-shadow: none;   
}
.change-address-button{
    background-color: #fff;
    border:none;
    position: absolute;
    margin-left: -60px;
}
.add-address-container h2{
    color:#1b3a57;
    font-size: 24px;
    text-align: start;
}
.add-address-container h3{
    color:#000;
    font-size: 18px;
    text-align: start;
    margin-bottom: 0;
}
.add-address-container span{
    color: #4d4d4d;
    font-size: 16px;
}
.label-for-input{
    text-align: start;
}
.form-control.add-address-form:focus{
    border:1px solid #d3d3d3;
    box-shadow: none;  
}
.form-select.add-address-form:focus{
    border:1px solid #d3d3d3;
    box-shadow: none;  
}
.save-address-button{
    background-color: #1B3A57;
    padding: 8px 24px;
    color:#FFFFFF;
    width:fit-content;
    border: none;
    border-radius: 5px;
}
.cancel-address-button{
    background-color: #f6f6f6;
    padding: 8px 24px;
    color:#4d4d4d;
    width:fit-content;
    border: none;
    border-radius: 5px;
}
.form-check-input:checked{
    background-color: #1B3A57;
    border-color: #1B3A57;
}
.profile-delivery-address{
    display: grid;
    grid-template-columns: 1fr 1fr ;
    grid-row-gap: 20px;
}
/* .delivery-address-details{
    border: 1px solid #1b3a57;
    padding:10px 20px;
    border-radius: 10px;
} */
@media (max-width: 1024px) {
    .addAddress-mobile{
        margin-top: 20px !important;
    }
}

@media (max-width: 768px) {
    
    .profile-delivery-address{
        display: flex !important;
        flex-direction: column;
    }
    .addAddress-mobile{
        margin-top: 20px;
    }
}
.error {
    color: red;
    font-size: 0.875em;
    margin-top: 0.25em;
}
.view-more-address-button{
    background-color: #1B3A57;
    color: #fff;
    border-radius: 20px;
    border: none;
    padding: 5px 8px;
}
.change-address-button.active {
    background-color: #1b2a57; /* Highlight color */
    color: white;
    border: 1px solid #d3d3d3; /* Border color */
    transition: background-color 0.3s ease; /* Smooth transition */
  }

  /* .error-placeholder {
    min-height: 20px; 
    margin-top: 4px; 
  } */
  
  .form-group {
    position: relative;
  }
  
  .error-container-details {
    position: relative; /* Changed from absolute */
    /* margin-top: 0.25rem;  */
    display: block;
  }
  
  .error-message-details-page {
    color: red;
    font-size: 0.875rem;
    line-height: 1rem;
  }
  
  .button-container-detalis {
    margin-left: auto;
    display: flex;
    align-items: center;
    padding-left: 10px; 
  }
  
 