.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    left: 3%;
    transform: translateX(-50%);
    background-color: rgb(197, 197, 197);
    color: #1b3a57;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 30px;
    cursor: pointer;
    display: none;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease-in-out;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  }
  
  .scroll-to-top-button.visible {
    display: flex;
  }
  
  .scroll-to-top-button:hover {
    background-color: #1b3a57;
    color: #fff;
  }