.empty-wishlist {
  background-color: #fff;
  padding: 20px;
  border: 2px solid #d3d3d3;
  width:400px;
  height:350px;
  margin-top: 20px;
  margin: auto;
}
.wishlist-like-symbol {
  background-color: #faeae9;
  font-size: 24px;
  color: #ce1b28;
  border-radius: 50%;
  padding: 30px;
}
.wishlist-row h2{
    text-align: left;
    color: #1b3a57;
}
.empty-wishlist h3{
    padding-top: 10px;
}
.wishlist-item{
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        padding:20px;
}
.wishlist-item h2{
    color:#000;
    font-size: 24px;
    text-align: left;
}
.wishlist-item p{
    color:#000;
    font-size: 18px;
    text-align: left;
    margin-bottom: 0;
    gap: 10px;
}
.wishlist-item .wishlist-price{
    text-decoration: line-through;
}
.wishlist-product-detail-discount{
    color: #fff !important;
    background-color: #1B3A57 !important;
    border-radius: 15px !important;
    font-size: 12px !important;
    padding: 5px 5px !important;
}
@media (max-width: 768px) {
    .media-wishlist{
        flex-direction: column;
       
    }
    .media-closeoutlined-wishlist{
        justify-content: space-between;
    }
    .media-wishlist-button{
        width: 100px !important;
    }
    .add-to-cart-buttons {
        background-color: #1b3a57 !important;
        border: none !important;
        border-radius: 15px !important;
        color: #f7f7f7 !important;
        font-size: 16px !important;
        padding: 5px 10px !important;
        width: 115px !important;
    } 
    .wishlist-item{
        margin-top: 40px;
    }
    .addtocart-button-mobile{
        justify-content: space-between;
    }
    
}

