.footer-container {
  padding: 20px;
  background-color: #f9f9f9;
}

.footer-column h5 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 18px;
  color: #1b3A57;

}

ul.footer-about-us {
  margin-right: 60px;
}

.footer-column ul {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
}

.footer-column li {
  margin: 5px 0;
  justify-content: start;
}

.footer-column a,
.footer-section .link {
  text-decoration: none;
  color: inherit;
}
.footer-column a:hover{
  color: rgb(0, 17, 255);

}

.footer-line {
  width: 70%;
  height: 2px;
  background-color: #B7B7B7;
  margin: 20px auto 0 auto;
}

.footer-bottom-row {
  text-align: center;
  margin-top: 1.5rem;
}

.footer-bottom-row a,
.footer-bottom-row .link {
  text-decoration: none;
  color: inherit;
}

.contact-icon {
  color: #1b3A57;
  height: 22px;
  width: 27px;
  font-size: 20px;
  border-radius: 25px !important;
  background-color: #f7f7f7;


}

.footer-row {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.footer-column {
  /* flex: 1; */
  /* min-width: 200px; */
  padding: 20px;
  text-align: left;
  line-height: 1.8rem;
}

.footer-subscribe {
  text-align: left;
}

.footer-subscribe input {
  padding: 8px;
  margin-right: 5px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border: solid #1b3A57 1px;
  width: 200px;
}

.footer-subscribe button {
  padding: 8px 30px;
  background-color: #1b3a57;
  border-radius: 16px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border: none;
  cursor: pointer;
  /* margin-left: -60px; */
  color: #f7f7f7;
  left: -9px;
  position: relative;
}

.footer-social {
  text-align: left;
}

.footer-social a {
  margin-right: 10px;
  color: inherit;
  font-size: 24px;
}

.footer-img-bg {
  position: absolute;
  right: 0;
  width: 240px;
  height: 180px;
  margin-top: -160px;
}

.footer-google {
  width: 150px;
  height: 50px;
}

.footer-tab-row {
  display: flex;
}

.footer-name {
  display: flex;
  flex-direction: row;
  /* gap: 40px; */
}

.footer-address-text {
  line-height: 8px !important;
}

.mobile-response-gap {
  display: flex !important;
  gap: 10px !important;
}

@media (max-width: 1286px) and (min-width: 840px) {
  .footer-subscribe input {
    padding: 4px;
    margin-right: 5px;
    border-radius: 16px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border: solid #1b3A57 1px;
  }

  .footer-subscribe button {
    padding: 4px 20px;
    background-color: #1b3a57;
    border-radius: 16px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border: none;
    cursor: pointer;
    margin-left: 2px;
    color: #f9f9f9;
  }
  .footer-contact-subscribe{
    display: flex;
    
  }
}

@media (max-width: 768px) {
  .footer-img-bg {
    display: none;
  }

  .footer-row {
    justify-content: left;
  }

  .footer-subscribe input {
    margin-left: -10px !important;
  }

  .media-footer {
    display: flex !important;
  }

  .footer-column {
    padding: 19px !important;
    padding-left: 3px !important;
  }

  .footer-tab-row {
    flex-direction: column !important;
  }

  .footer-name {
    display: flex;
  }

  .footer-container {
    padding: 2px !important;
    padding-left: 17px !important;
  }

  .footer-copyrights-div {
    float: left;
    padding-left: 23px;
    padding-right: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-response-gap {
    display: flex !important;
    gap: 20px !important;
  }

  ul.footer-about-us {
    margin-right: 50px !important;
  }

  .footer-column ul {
    width: 100% !important;
  }
  .footer-contact-subscribe{
    display: flex;
    flex-direction: column;
  }
}
@media  (min-width: 1183px){
  .footer-contact-subscribe{
    display: flex !important;
    gap: 50px !important;
  }
  .footer-name {
    display: flex;
    gap: 20px !important;
  }
}

@media (min-width: 768px) and (max-width: 1183px) {
  /* .footer-row {
    display: flex !important;
    flex-wrap: nowrap;
  } */

  .footer-tab-row {
    flex-direction: column !important;
  }
  .footer-contact-subscribe{
    display: flex;
    
  }
  .footer-padding-tab{
    padding-left: 20px !important;
    /* padding-right: 20px !important; */
  }
  
@media (min-width: 768px) and (max-width: 1183px){
  .footer-column{
    min-width: 430px !important;
  
  }
}
}

@media only screen and (min-width: 1200px) {}

.address {
  white-space: pre-wrap;
  /* Maintains line breaks and whitespace */
}



/* New footer */

.social-icons {
  display: flex;
  margin: 0 !important; ;
}

.social-icon {
  list-style: none;
}

.social-link {
  width: 35px;
  height: 35px;
  background-color: #fff;
  text-align: center;
  line-height: 32px;
  font-size: 20px !important; 
  margin: 0 5px; 
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: 3px solid #fff;
   z-index: 1; 
}

.social-link .icon {
  position: relative;
  color: #262626;
  transition: .5s;
  /* z-index: 3; */
}

.social-link:hover .icon {
  color: #fff;
  transform: rotateY(360deg);
}

.social-link:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f00;
  transition: .5s;
  /* z-index: 2; */
}

.social-link:hover:before {
  top: 0;
}

.social-icon:nth-child(1) .social-link:before {
  background: #3b5999; /* Facebook */
}

.social-icon:nth-child(2) .social-link:before {
  background: #55acee; /* Twitter */
}

.social-icon:nth-child(3) .social-link:before {
  background: #0077b5; /* LinkedIn */
}

.social-icon:nth-child(4) .social-link:before {
  background: #dd4b39; /* Google+ */
}
.bold-icon {
  /* text-shadow: 0 0 2px black; Adjust color and thickness */
  font-weight:bolder; /* Optional: Increase size for a thicker effect */
}