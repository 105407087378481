/* ShoppingCart.css */

.shop-img-cart {
  width: 120px;
  height: 120px;
  display: flex;
}

.sub-total-price {
  border-bottom: 1px solid #d3d3d3;
  padding-top: 20px;
}

.sub-discount {
  text-align: justify;
}

.sub-discount p {
  margin-bottom: 4px;
}

.total-price p {
  margin-bottom: 4px;
  text-align: right;
}

.quantity-cart-selector {
  border-radius: 20px;
  background-color: #d3d3d3;
  padding: 5px 10px;
  margin-top: 70px;
}

.cupon-button button {
  border-radius: 20px;
  background-color: #1b3a57;
  color: #fff;
  padding: 5px 15px;
  height: 35px !important;
  font-size: 14px !important;
}

.cupon-button input {
  border-radius: 20px;
  border: none;
  background-color: #d3d3d3;
  padding: 5px 10px;
  width: 300px;
}

.left-main-cart {
  border-bottom: 1px solid #d3d3d3;
  padding: 20px 10px;
}

.right-total {
  border: 1px solid #d3d3d3;
}

.total-price {
  font-weight: 600;
}

.shopping-step2-buttons-div {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.next-button-cart {
  border-radius: 20px;
  width: 150px;
  background-color: #1b3a57;
  padding: 10px 20px;
  color: #fff;
  align-items: center;
}

.form-control:focus,
.form-select:focus {

  border-color: #1b3a57;
  box-shadow: none;

}

.shopping-step2-buttons-div {
  display: flex;
  justify-content: space-between;
  width: auto;
}

.payment-option-product-div {
  height: 498px;
  overflow-y: auto;
  overflow-x: hidden;
}



.payment-option-product-div::-webkit-scrollbar {
  width: 4px;
}

.payment-option-product-div::-webkit-scrollbar-thumb {
  background-color: #1b3a57;
}

.payment-option-product-div::-webkit-scrollbar-track {
  background-color: transparent;
}

@media (max-width: 768px) {
  .quantity-cart-selector {
    margin-top: 50px !important;
    justify-content: center !important;
  }
  .ant-steps .ant-steps-item-container {
    width: 100px !important;
  }
}
@media (max-width: 1024px) {
  .quantity-cart-selector {
    padding: 0px !important;
  }
  /* .cupon-button {
    flex-direction: column; 
    align-items: stretch; 
    gap: 10px;
  } */
  .cupon-button input {
    width: 230px !important;
  }
}
@media (max-width: 760px) {

  /* .payment-option-product-div{
        width: 90% !important;
        margin: auto;
        padding-left: 20%;
    } */
  /* .left-main-cart {
    display: flex;
    flex-direction: column;
    gap: 20px;

  } */
  .quantity-cart-selector {
    margin-top: 0px !important;
    justify-content: center !important;
  }
  .ant-steps .ant-steps-item-container {
    width: 60px !important;
  }
  .cash-on-delivery-mob {
    margin-left: 0px !important;
  }

  .cupon-button input {
    width: 170px !important;
    padding: 5px 8px !important;
  }

}

/* =====css  by keerthi========== */
.payment-page-container {
  margin-top: 50px;
}

.left-main-cart:last-child {
  border-bottom: none;
}

.shopping-cart-card-details {
  text-align: left;
}

.shopping-cart-card-details p {
  margin-bottom: 0;
}

.payment-form {
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: start;
}

.payment-form h3 {
  color: #1b3a57;
  font-size: 24px;
  text-align: left;
  margin-bottom: 20px;
}

.payment-option {
  margin-bottom: 5px;
}

.payment-radio {
  font-size: 20px;
}

.payment-form h6 {
  font-size: 16px;
  color: #828282;
  padding-left: 23px;
}

.payment-options-description h5 {
  padding-top: 20px;
  font-size: 16px;
}

.payment-options-description span {
  font-size: 13px;
  color: #828282;
  /* padding-bottom: 20px; */
}

:where(.css-dev-only-do-not-override-17seli4).ant-upload-wrapper .ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #1b3a57;
}

.pdf-document {
  border: 1px solid lightgreen;
  padding: 10px;
  border-radius: 25px;
}

.end-button {
  background-color: #1b3a57;
  color: #d3d3d3;
  border-radius: 25px;
  padding: 10px;
}

.paytm-upi {
  border: 1px solid #d3d3d3;
  border-radius: 25px;
  width: auto;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  /* background-color: #d3d3d3; */
}

.input-upi,
.input-upi:focus-visible {
  border: none;
}

.transaction-card {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
}

.payment-upi {
  height: auto;
  width: 70px;
  border: 1px solid #d3d3d3;
  padding: 5px;
  border-radius: 10px;
}

.phonepay-upi {
  height: auto;
  width: 50px;
  border: 1px solid #d3d3d3;
  padding: 10px;
  border-radius: 10px;
}

.googlepay-border {
  border-bottom: 1px solid #d3d3d3;
}

.empty-cart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  border-radius: 25px;
}

.empty-cart-container img {
  width: 300px;
  height: auto;
}

.empty-cart-container h3 {
  margin-top: 20px;
}

.empty-cart-container p {
  margin-bottom: 20px;
}

.empty-cart-container .continue-button {
  padding: 10px 20px;
  background-color: #1b3a57;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


/*----- step process css ----- */
.ant-steps.ant-steps-small .ant-steps-item-icon {
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  align-content: center;
  border-radius: 0;
  transform: rotate(45deg);
  margin-left: 25px;
  border-radius: 7px;
}

.ant-steps .ant-steps-item-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

:where(.css-dev-only-do-not-override-17seli4).ant-steps .ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon {
  color: #fff;
  display: block;
  transform: rotate(-45deg);
  position: relative;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #1b3a57;
  border-color: #1b3a57;
}

.ant-steps .ant-steps-item-process .ant-steps-item-content>.ant-steps-item-title {
  color: #1b3a57;
  font-size: 20px;
}

.ant-steps.ant-steps-small .ant-steps-item-title::after {
  display: none;
  top: -37px;
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: #1b3a57;
}

.ant-steps.ant-steps-small .ant-steps-item-title {
  font-size: 20px;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon {
  color: rgba(0, 0, 0, 0.65);
  display: block;
  transform: rotate(-45deg);
  position: relative;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #1b3a57;
  border-color: #1b3a57;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  color: #fff;
  transform: rotate(-45deg);
  display: block;
}

.payment-optn-expiry-security {
  display: flex;
}

/* Sucesspopform.css */
.order-summary-payment {
  border: 1px solid #d9d9d9;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 10px !important;
}

.header-order-summary {
  border-bottom: 1px dashed #d3d3d3;
}

.successpopup-total {
  border-top: 1px dashed #d3d3d3;
}

.header-order-summary p {
  font-size: 14px !important;
}

.total-table-failure {
  font-size: 14px !important;
}

.address-container {
  display: flex;
  align-items: flex-start;
  gap: 8px;

}

.address-radio-button {
  margin-top: 4px;
}

.delivery-shopping-step {
  margin-left: 8px;
}

.delivery-shopping-step {
  text-align: left;
}

.delivery-shopping-step h6,
.delivery-shopping-step p {
  margin: 0;
  line-height: 1.5;
}

.shipping-title {
  white-space: nowrap;
  /* Ensures the title stays on one line by default */
}
:where(.css-dev-only-do-not-override-17seli4).ant-steps.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: flex;
}
:where(.css-dev-only-do-not-override-17seli4).ant-steps.ant-steps-small .ant-steps-item-tail {
  top: 30px;
  left: 120px;
  width: 80px;
}

@media (max-width: 700px) {
  .shoppingstep1-delete-icon {
    position: relative !important;
    top: -230px !important;
    left: 90px !important;
  }
}
@media (max-width: 1024px) {
  .address-divs-box {
    grid-template-columns: 1fr 1fr !important; /* Two columns on iPad screens */
  }
}

@media (max-width: 768px) {
  .ant-steps.ant-steps-small .ant-steps-item-icon {
    width: 30px;
    height: 30px;
    font-size: 14px;
    text-align: center;
    align-content: center;
    border-radius: 0;
    transform: rotate(45deg);
    margin-left: 25px;
    border-radius: 7px;
  }

  .steps-antd {
    width: 457px !important;
    /* padding: 10px; */
    display: flex !important;
    flex-direction: row !important;
    margin-bottom: 25px !important;
  }

  .steps-antd .item-title {
    font-size: 10px;
  }

  .ant-steps .ant-steps-item-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .ant-steps-item-title {
    font-size: 12px !important;
    margin-left: 10px;

  }

  :where(.css-dev-only-do-not-override-17seli4).ant-steps.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
    line-height: 20px;
    width: 150px !important;
  }

  .ant-steps.ant-steps-vertical>.ant-steps-item:not(:last-child)>.ant-steps-item-container>.ant-steps-item-tail {
    display: block;
    left: 110px;
    transform: rotate(90deg);
    margin-top: -10px;
    background-color: #808080;
    width: 1px;
    height: 40px;
    top: 15px;
  }

  .shopping-step1-div,
  .shopping-step2-div {
    width: auto !important;
    margin: 10px !important;
  }

  .shopping-step2-div {
    margin-top: 60px !important;
  }

  .shopping-step2-delivery-ops {
    display: flex;
    flex-direction: column;
  }

  .shopping-step2-delivery-div {
    display: flex;
    flex-direction: column;
  }

  .shopping-step2-buttons-div {
    display: flex;
    justify-content: space-between;
    width: auto;
    margin-top: 30px;
  }

  .payment-form {
    margin-top: 20px;
    padding-left: 15px !important;
  }

  .cupon-button {
    display: flex;
    /* flex-direction: column; */
  }

  .order-summary-card {
    height: 415px !important;
    margin-top: 25px;
  }

  .step-process-media {
    width: 348px !important;
    height: 100px !important;
    overflow: hidden !important;
  }

  .form-check-label {
    font-size: 15px !important;
  }

  .faq-get-in-touch-button {
    font-size: 13px !important;
  }

  .address-divs-box {
    grid-template-columns: none !important;
  }

  .buttons-edit-details {
    font-size: 10px !important;
  }

  .form-check-label {
    font-size: 14px !important;
  }
  .shipping-title {
    white-space: normal; 
  }

  .shipping-part {
    display: block;
  }

  .checkout-part {
    display: block; 
  }


}


.quantity-input {
  width: 50px;
  background: transparent;
  border: none;
}

.quantity-input:focus {
  outline: none;
}

.delivery-address {
  border: 1px solid #1b3a57;
  padding: 10px 20px;
  border-radius: 10px;
  width: 300px;
  /* margin-top: 20px; */
  height: 240px;
}

.address-divs-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 20px;
}



/*-------------- coupon code css --------------*/

.order-summary-card {
  height: 300px;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.order-summary-card.expanded {
  height: 450px;
  overflow: visible;
}

.coupon-applied-section {
  display: none;
}

.coupon-applied-section.visible {
  display: block;
}


.quantity-cart-selector-steel {
  border-radius: 20px;
  background-color: #d3d3d3;
  padding: 5px 10px;
  /* margin-top: 70px; */
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;

}

.quantity-change-input {
  background-color: #d3d3d3;
  width: 50px;
  border: none;
  text-align: center;
}

.quantity-change-input:focus-visible {
  outline: none;
}

.straight-line-border {
  border: 0.5px solid #d3d3d3;
}

.cart-option-product-div {
  max-height: 537px;
  /* height:537px; */
  overflow-y: auto;
}

.cart-option-product-div::-webkit-scrollbar {
  width: 4px;
}

.cart-option-product-div::-webkit-scrollbar-thumb {
  background-color: #1b3a57;
}

.cart-option-product-div::-webkit-scrollbar-track {
  background-color: transparent;
}

.disabled-next-button {
  background-color: #828282;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .products-card-main {
    width: 219px !important;
  }

  .products-card-container {
    grid-template-columns: 1fr 2fr 2fr !important;
  }

}

.payment-file-upload.ant-upload-wrapper .ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 0px;
}

.bank-transfer-input {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 3px;
}

.bank-transfer-input p {
  margin-bottom: 0;
}

.bank-details-form-group p {
  margin-bottom: 0;
  text-align: left;
  /* margin-top: 15px; */

}

.current-location-icon {
  border: 1px solid #1b3a57;
  padding: 6px 10px;
  width: fit-content;
  border-radius: 20px;
  cursor: pointer;
  background-color: #1b3a57;
  color: #fff;
  align-items: center;
}

.current-location-icon h5 {
  margin-bottom: 0;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}



.ant-upload-list-item-container {
  display: none;
}

.preview-image {
  cursor: pointer;
  display: flex;
  max-width: 100px;
  max-height: 100px;
}

.preview-image-delete {
  display: flex;
  width: 100px;
  align-items: center;
  justify-content: space-between;
}

.quantity-button {
  background: none;
}

/* .form-card-main{
  margin-top: 20px;
    padding:15px;
    border:1px solid #e1e1e1;
    border-radius: 10px;
    height:350px;

} */
.paymentDeatils-form-input {
  margin-top: 5px;
  padding: 8px;
  border-radius: 7px;
  background-color: #FFFFFF;
  border: 1px solid #e1e1e1;
  box-shadow: #889cbb 0px 2px 3px -2px, rgba(0, 0, 0, 0.1) 0px 1px 3px -1px;

}
:where(.css-dev-only-do-not-override-17seli4).ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: #e1e1e1;
}
.checkbox-shopping-step2{
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}